import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_PRINTERS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Printer"
    }
  }, [_c("text-input", {
    key: "name",
    attrs: {
      "label": "Printer Name",
      "placeholder": "Type Name",
      "span": 24,
      "rules": "max:30"
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Printer Description",
      "placeholder": "Type Description",
      "span": 24,
      "rules": "max:50"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditPrinters"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditPrinters = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-printers"
  }, [_c("resource", {
    attrs: {
      "name": "grower.printers",
      "api-url": _vm.apiUrl,
      "edit": _vm.EditPrinters,
      "redirect-route": "/watties-grower/printers",
      "page": _vm.page
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditPrinters",
  data() {
    return {
      EditPrinters,
      apiUrl,
      page: GROWERS_PRINTERS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
